import axios from 'axios';

//export const server = 'https://xyna.ca/api/';
//export const server = 'https://localhost:44317/api/';
export const server = '/api/';
const KeyValues = [];

export const req = axios.create({
  baseURL: server,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

req.interceptors.response.use(
  (response) => {
    console.log('response from interceptor');
    console.log(response);
    return response;
  },
  (error) => {
    console.log('interceptor response error ');
    console.log(error);
    return error;
  }
);

export const GetKeyValueList = async () => {
  if (KeyValues.List != '' && KeyValues.List != null) {
    return KeyValues.List;
  } else {
    const res = await req(`ListKeys`).catch(function (ListValuesResponse) {
      // console.log(BusinessTransactionsResponse);
      base.notify_Error('Error Getting ListValuesResponse', 'Failure!');
    });
    console.log(res);
    KeyValues.List = res;
    return KeyValues.List;
  }
};

export const GetTestDatesFromServer = async () => {
  let returnObj = '';
  console.log('getting test dates from server');

  // Get the current date
  var currentDate = new Date();

  // Extract the current year and month
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

  // Calculate the remaining months in the year
  var remainingMonths = 12 - currentMonth + 1; // Adding 1 to include the current month

  // Generate an array of remaining months
  var remainingMonthsArray = [];
  for (var i = 0; i < remainingMonths; i++) {
    remainingMonthsArray.push(currentMonth + i);
  }

  // Create the dynamic "dates" object
  var dynamicDates = [
    {
      year: currentYear,
      months: remainingMonthsArray,
    },
  ];

  var inputData = `{
        "districtIds":[1229,3329,6628,11759,12251,12252,12370,12405,12423,13129,13158,13169],
        "dates":${JSON.stringify(dynamicDates)},
        "ieltsModule":"ac",
        "daysToNearestPbExam":0,
        "daysToNearestCdExam":0,
        "organisationId":177,
        "hasSpecialArrangements":false
    }`;

  //var dynamicInputDataString = JSON.stringify(inputData, null, 4);

  returnObj = await req
    .post(
      //'https://ieltsregistration.britishcouncil.org/api/DistrictExams/IELTS?',
      'https://ieltsregistration.britishcouncil.org/modernisation/api/organisations/configuration/PF~IELTS?',
      JSON.parse(inputData)
    )
    .then((result) => {
      console.log('test dates response from the server ', result);
      return result;
    });

  /*  var inputDatagt = `{
          "districtIds":[1229,3329,6628,11759,12251,12252,12370],
          "dates":[{"year":2021,"months":[1,2,3,4,5,6,7,8,9,10,11,12]}],
          "ieltsModule":"gt",
          "daysToNearestPbExam":0,
          "daysToNearestCdExam":0,
          "organisationId":177,
          "hasSpecialArrangements":false
      }`
      let returnObjgt = await req.post('https://ieltsregistration.britishcouncil.org/api/DistrictExams/IELTS?', JSON.parse(inputDatagt)).then(result => {
          console.log('test dates response from the server GT ', result);
          return result;
      })
  */

  return returnObj;
};
