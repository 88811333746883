import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import notification, { options } from "toastr";

let cfg = {};

export const ToHTML = (delta) => {
    return ToHTMLFromBlocks(delta);
}

export const ToHTMLWithFormatting = (delta) => {

    return ToHTMLFromBlocks(delta);
}


export const ToHTMLFromBlocks = (data) => {

    let blocks = JSON.parse(data).blocks;
    if (blocks !== undefined) {
        // console.log("🚀 blocks data", blocks)
        var convertedHtml = "";
        blocks.map(block => {

            switch (block.type) {
                case "header":
                    convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case "embed":
                    convertedHtml += `<div><iframe width="100%" height="465" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
                    break;
                case "paragraph":
                    convertedHtml += `<p>${block.data.text}</p>`;
                    break;
                case "delimiter":
                    convertedHtml += "<hr />";
                    break;
                case "image":
                    convertedHtml += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
                    break;
                case "list":
                    convertedHtml += "<ul class=\"list mt-2\">";
                    block.data.items.forEach(function (li) {
                        convertedHtml += `<li><i class="far fa-circle"></i>${li}</li>`;
                    });
                    convertedHtml += "</ul>";
                    break;
                case "table":
                    convertedHtml += "<table class=\"table table-striped border mb-4\">";
                    var tc = 0;
                    block.data.content.forEach(e => {

                        convertedHtml += "<tr>";
                        e.forEach(i => {
                            convertedHtml += `<td>${i}</td>`;
                        })
                        convertedHtml += "</tr>";
                    })
                    convertedHtml += "</table>";
                default:
                    console.log("Unknown block type", block.type);
                    console.log(" block data", block);
                    break;
            }
        });
    }
    return convertedHtml;

}



export const GetImageURLfromBlocks = (data) => {

    let blocks = JSON.parse(data).blocks;
    if (blocks !== undefined) {
        // console.log("🚀 blocks data", blocks)
        var convertedHtml = "";
        blocks.map(block => {

            switch (block.type) {
                case "image":
                    convertedHtml += block.data.file.url;
                    break;
            }
        });
    }
    return convertedHtml;

}

export const GetNonImagefromBlocks = (data) => {

    let blocks = JSON.parse(data).blocks;
    if (blocks !== undefined) {
        // console.log("🚀 blocks data", blocks)
        var convertedHtml = "";
        blocks.map(block => {

            switch (block.type) {
                case "header":
                    convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case "embed":
                    convertedHtml += `<div><iframe width="100%" height="465" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
                    break;
                case "paragraph":
                    convertedHtml += `<p>${block.data.text}</p>`;
                    break;
                case "delimiter":
                    convertedHtml += "<hr />";
                    break;
                case "image":
                    convertedHtml += ``;
                    break;
                case "list":
                    convertedHtml += "<ul class=\"list mt-2\">";
                    block.data.items.forEach(function (li) {
                        convertedHtml += `<li><i class="far fa-circle"></i>${li}</li>`;
                    });
                    convertedHtml += "</ul>";
                    break;
                case "table":
                    convertedHtml += "<table class=\"table table-striped border mb-4\">";
                    var tc = 0;
                    block.data.content.forEach(e => {

                        convertedHtml += "<tr>";
                        e.forEach(i => {
                            convertedHtml += `<td>${i}</td>`;
                        })
                        convertedHtml += "</tr>";
                    })
                    convertedHtml += "</table>";
                default:
                    console.log("Unknown block type", block.type);
                    break;
            }
        });
    }
    return convertedHtml;

}

export const GetRawLinkFromPBlock = (data) => {

    let blocks = JSON.parse(data).blocks;
    if (blocks !== undefined) {
        // console.log("🚀 blocks data", blocks)
        var convertedHtml = "";
        blocks.map(block => {

            switch (block.type) {
                case "header":
                    convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case "embed":
                    convertedHtml += `<div><iframe width="100%" height="465" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
                    break;
                case "paragraph":
                    convertedHtml += `${block.data.text}`;
                    break;
                case "delimiter":
                    convertedHtml += "<hr />";
                    break;
                case "image":
                    convertedHtml += ``;
                    break;
                case "list":
                    convertedHtml += "<ul class=\"list mt-2\">";
                    block.data.items.forEach(function (li) {
                        convertedHtml += `<li><i class="far fa-circle"></i>${li}</li>`;
                    });
                    convertedHtml += "</ul>";
                    break;
                case "table":
                    convertedHtml += "<table class=\"table table-striped border mb-4\">";
                    var tc = 0;
                    block.data.content.forEach(e => {

                        convertedHtml += "<tr>";
                        e.forEach(i => {
                            convertedHtml += `<td>${i}</td>`;
                        })
                        convertedHtml += "</tr>";
                    })
                    convertedHtml += "</table>";
                default:
                    console.log("Unknown block type", block.type);
                    break;
            }
        });
    }
    return convertedHtml;

}


export const renderSpinner = parent => {
    const loader = `<div id="spinner" class="spinner-border text-primary ml-2" role="status">
    <span class="sr-only">Loading...</span>
</div>`;

    parent.insertAdjacentHTML('afterend', loader);
    parent.disabled = true;
};

export const renderSpinnerButton = parent => {
    parent.innerHTML = '';
    const loader = `<span class="spinner-border spinner-border-sm"></span>
        Processing Payment..`;

    parent.insertAdjacentHTML('beforeend', loader);
    parent.disabled = true;
};
export const clearSpinnerButton = parent => {
    parent.innerHTML = 'Pay Now';
    // console.log("clearLoader -> elementStrings.loader", elementStrings.loader)
    parent.disabled = false;
};



export const clearSpinner = () => {
    const loader = document.querySelector(`#spinner`);
    // console.log("clearLoader -> elementStrings.loader", elementStrings.loader)
    console.log("clearLoader -> loader", loader)
    if (loader) {
        loader.parentElement.removeChild(loader);

    }
};



export const notify_Info = (message, title = 'Info') => {

    notification.info(message, title, { positionClass: 'toast-bottom-full-width' });

}

export const notify_Success = (message, title = 'Success') => {

    notification.success(message, title, { positionClass: 'toast-bottom-full-width' });

}

export const notify_Warning = (message, title = 'Warning') => {

    notification.warning(message, title, { positionClass: 'toast-bottom-full-width' });


}

export const notify_Error = (message, title = 'Error') => {

    notification.error(message, title, { positionClass: 'toast-bottom-full-width' });

}